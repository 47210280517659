/* @tailwind base; */
@import "preflight.css";
/* @import "navbar.css"; */
@tailwind components;
@tailwind utilities;

/* .ce-header {
    padding: 0.6em 0 3px !important;
    margin: 0 !important;
    line-height: 1.25em !important;
    outline: none !important; */
    /* font-size: 32px !important; */
    /* font-weight: 700 !important;
} */ 

/* #editorjs {
    max-width: 60vw;
    margin:auto;
} */

.codex-editor--narrow .ce-toolbar__actions {
    right: 100%;
}

body {
    /* position: fixed; */
    /* width: 100%; */
    /* height: 100%; */
    /* overflow: hidden; */
    /* overscroll-behavior-y: none; */
    /*width: 100vw;
    height: 100vh;*/
    /* overflow: hidden; */
    
    -webkit-overflow-scrolling: touch;
}
